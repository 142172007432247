// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {

        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {

    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {

    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

@keyframes scaleDraw {

    /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
    0% {
        transform: scale(1);
        /*开始为原始大小*/
    }

    25% {
        transform: scale(1.1);
        /*放大1.1倍*/
    }

    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(1.3);
    }
}

@keyframes bounceInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-150px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        transform: translate3d(-10px, 0, 0);
    }

    90% {
        transform: translate3d(-5px, 0, 0);
    }

    100% {
        transform: none;
    }
}

@keyframes bounceInRight {
    0% {
        opacity: 0;
        transform: translate3d(150px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0);
    }

    75% {
        transform: translate3d(10px, 0, 0);
    }

    90% {
        transform: translate3d(5px, 0, 0);
    }

    100% {
        transform: none;
    }
}

.scale2 {
    cursor: pointer;
    transform: scale(1);
    transition: 1s;
    width: 100%;
    z-index: 1;

    &:hover {
        transform: scale(1.1);
        transition: 2s;
    }
}

.scale__auto {
    width: 100%;
    object-fit: cover;
    animation: scaleDraw 15s linear infinite;

    &__wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}

@keyframes scaleDraw {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.05);
    }

    50% {
        transform: scale(1.1);
    }

    75% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.scale__auto2 {
    width: 100%;
    overflow: hidden;
    animation: scaleDraw2 10s linear infinite;
    background-position: center;

    &__wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}

@keyframes scaleDraw2 {
    0% {
        background-size: 100%;
    }

    25% {
        background-size: 105%;
    }

    50% {
        background-size: 110%;
    }

    75% {
        background-size: 105%;
    }

    100% {
        background-size: 100%;
    }
}

.autoH {
    height: 58vw !important;
}

.myBoard {
    .szImg {
        width: 50%;
        padding: 0 20px 20px 0;
        box-sizing: border-box;
    }
}

@media (max-width: 768px) {
    .myBoard {
        .szImg {
            width: 100%;
            padding: 0;
            margin-bottom: 20px;
        }
    }
}

.slideImg {
    width: 100%;
    height: auto;
}

.slideLine:hover {
    .lineLeft {
        animation: bounceInLeft 1s linear 0s 1 alternate forwards;
        -webkit-animation: bounceInLeft 1s linear 0s 1 alternate forwards;
        /*Safari and Chrome*/
    }

    .lineRight {
        animation: bounceInRight 1s linear 0s 1 alternate forwards;
        -webkit-animation: bounceInRight 1s linear 0s 1 alternate forwards;
        /*Safari and Chrome*/
    }
}

.css-e276g8-MuiButtonBase-root-MuiMenuItem-root:hover {
    background-color: rgba(32, 132, 219, 0.15);
}

.fullWidth {
    width: 100%;
    height: auto;
}

.halfWidth {
    width: 50%;
    height: auto;

    &.center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 768px) {
    .halfWidth {
        width: 100%;
    }
}

body {
    font-family: 'PingFangSC-Regular, PingFang SC';
}

.underLine {
    position: relative;

    &::after {
        content: ' ';
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 56px;
        height: 2px;
        background: #2084db;
    }
}

.underlineTab {
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    padding-bottom: 4px;
    border-bottom: 6px solid #333333;
}

.innerText {
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 84px;
    text-shadow: 0px 0px 8px #000000;
}

.css-1d4ohgu-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: auto;
}

.SzInfoImg {
    width: 100%;
    height: auto;
}

.companyInfoImg {
    width: 70%;
    height: auto;
}

.mobileFullWidth {
    width: 100%;
    height: auto;
}

.MuiButtonBase-root.MuiListItemButton-root.Mui-selected {
    background-color: #ffffff;
}

.caseList .MuiImageListItemBar-title {
    font-size: 1.5rem;
    font-weight: 700;
}

.caseList .MuiImageListItemBar-subtitle {
    margin-top: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    font-size: 1rem;
    font-weight: 700;
}

.caseList .MuiImageListItem-img {
    filter: blur(0.5px);
}

.detailHead {
    font-weight: 500;
}

.detailHeadB {
    font-weight: 400;
}

.footLink {
    color: #000000;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #2084db;
        // color: #fff;
    }
}

*,
*::before,
*::after {
    z-index: 3;
}

// .clickImg::after {
//     content: '';
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.2);
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 2
// }

.homeFooter {
    display: flex;
    margin-bottom: 50px;
    margin-left: 50px;
}

@media (max-width: 768px) {
    .homeFooter {
        display: block;
        border-bottom: none;

        &:last-of-type {
            div:last-of-type {
                text-align: left;

                p:first-of-type {
                    margin-top: 10px;
                    justify-content: flex-start;
                }
            }
        }
    }

    .homeFooterCard {
        margin-top: 20px;
    }
}

.homeFooterCard {
    background-color: #2084DB;
    color: white;
    padding: 25px 0;
    text-align: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 180px;
    text-decoration: none;

    p {
        font-size: 10px;
    }

    p+p {
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px;
    }
}

a.homeFooterCard {
    background-color: darken($color: #2084DB, $amount: 10%);
}